.fadescroll {
  transition: all 0.8s;
  opacity: 0;

  @include breakpoint(medium down) {
    opacity: 1;
  }
}

.fade {
  opacity: 1;
}

.moveleft {
  opacity: 0;
  transform: translateX(-200px);
  transition: all 0.6s ease-in-out;

  @include breakpoint(medium only) {
    opacity: 1;
    transform: none;
  }
}

.left {
  opacity: 1;
  transform: translateX(0px);
}
