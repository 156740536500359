$color-main: $stahlblau;
$color-active: $frz-ocker30;
$color-link: $leichtblau;

$button-height: 27px;
$button-width: 35px;


.button_container {
  position: fixed;
  top: 3%;
  right: 2%;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity .25s ease;

  &:hover {
    opacity: .7;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: $color-active;
    }
    .middle {
      opacity: 0;
      background: $color-active;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: $color-active;
    }
  }

  span {
  background: $frz-ocker30;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition:  all .35s ease;
  cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}

.overlay {
  position: fixed;
  background: $color-main;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  padding: 2vw;
  opacity: 0;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, height .35s;
  overflow: hidden;
  z-index: 23;

  &.open {
    opacity: .9;
    visibility: visible;
    height: 100%;

    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: .35s;

      &:nth-of-type(2) {
        animation-delay: .4s;
      }
      &:nth-of-type(3) {
        animation-delay: .45s;
      }
      &:nth-of-type(4) {
        animation-delay: .50s;
      }
    }
  }
  nav {
    position: relative;
    height: 70%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 6vw;
    font-weight: 900;
    text-align: right;
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;

    li {
      display: block;
      height: 25%;
      height: calc(100% / 4);
      min-height: 50px;
      position: relative;
      opacity: 0;

      a {
        display: block;
        position: relative;
        color: $color-link;
        text-decoration: none;
        overflow: hidden;

        &:hover:after,
         &:focus:after,
         &:active:after {
           width: 50%;
         }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: -50%;
          width: 10%;
          transform: translateX(-50%);
          height: 10px;
          background: $frz-ocker30;
          transition: .35s;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
