$hero-height: 92vh;

.hero-section {
  margin-top: 8vh;
  height: $hero-height;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-color: $stahlblau;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  @include breakpoint(medium only) {
    background-attachment: scroll;
  }

  @include breakpoint(small only) {
    height: 50vh;
    background-attachment: scroll;
  }

  .hero-section-text {

    h1 {
      font-size: 6vw;
      font-weight: 900;
      color: $white;
    }
  }
}
