.footer {
  min-height: 20vh;
  font-weight: 300;
  // text-transform: uppercase;
  background-color: $stahlblau;
  color: $frz-ocker30;

  .footer-content-box {
    height: 100%;

    .footer-content-left {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      padding: 3rem;
      background-color: lighten($stahlblau, 6%);

    }
    .footer-content-middle {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      padding: 3rem;
      text-align: center;
      background-color: lighten($stahlblau, 2%);

      img {
        margin-bottom: 2rem;
        width: 50%;
      }
    }
    .footer-content-right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding-right: 2vw;
      height: 100%;
      width: 100%;


      // @include breakpoint(small only) {
      //   align-items: center;
      //   text-align: center;
      // }

      .footer-links {
        width: 70%;
      }

      li {
        padding-top: 2rem;
      }

      a {
        display: block;
        position: relative;
        color: $bildblock-color;
        text-decoration: none;
        text-align: right;
        text-transform: uppercase;
        font-weight: 900;
        overflow: hidden;

        &:hover:after,
         &:focus:after,
         &:active:after {
           width: 50%;
         }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: -50%;
          width: 10%;
          transform: translateX(-50%);
          height: 2px;
          background: $frz-ocker30;
          transition: .35s;
        }
      }
    }
  }
}
