$textblock-color: $leichtblau;
$textblock-height: 50vh;

.linkblock {
  background-color: $stahlblau;
  height: $textblock-height;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  width: 100%;

  .linkblock-title {
    h2 {
      // line-height: rem-calc(160);
      color: $textblock-color;
      font-size: 6vw;
      font-weight: 900;
      text-transform: uppercase;

      @include breakpoint(small only) {
        line-height: rem-calc(30);
      }

      a {
        color: $textblock-color;
        display: block;
        position: relative;
        text-decoration: none;
        overflow: hidden;

        &:hover:after,
         &:focus:after,
         &:active:after {
           width: 50%;
         }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: -50%;
          width: 10%;
          transform: translateX(-25%);
          height: 10px;
          background: $frz-ocker30;
          transition: .35s;
        }
      }
    }

    p {
      font-size: 2vw;
      font-weight: 900;
      color: $frz-ocker30;

      a {
        color: $textblock-color;
        display: block;
        position: relative;
        text-decoration: none;
        overflow: hidden;

        &:hover:after,
         &:focus:after,
         &:active:after {
           width: 50%;
         }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          right: -50%;
          width: 10%;
          transform: translateX(-15%);
          height: 5px;
          background: $frz-ocker30;
          transition: .35s;
        }
      }
    }


  }
  //
  // @include breakpoint(small only) {
  //   height: ($textblock-height / 1.5);
  // }
}
