$textblock-color: $stahlblau;
$textblock-height: 50vh;

.textblock {
  height: $textblock-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;

  .textblock-title-sub {
    color: $textblock-color;
    font-size: 3vw;
  }
  .textblock-title {
    h2 {
      line-height: rem-calc(100);
      color: $textblock-color;
      font-size: 6vw;
      font-weight: 900;
      text-transform: uppercase;

      @include breakpoint(small only) {
        line-height: rem-calc(30);
      }
    }
  }
  //
  // @include breakpoint(small only) {
  //   height: ($textblock-height / 1.5);
  // }
}
