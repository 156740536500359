$text-bildblock-color: $white;
$text-bildblock-height: 90vh;
$text-bildblock-height-small: 50vh;

.text-bildblock {
  background-color: $stahlblau;
  height: $text-bildblock-height;

  // @include breakpoint(medium) {
  //   height: 80vh;
  // }
  @include breakpoint(small only) {
    height: $text-bildblock-height-small;
  }
}

.text-bildblock-content {
  height: 100%;
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.text-bildblock-center-container {
  position: absolute;
  bottom: rem-calc(70);
  left: 0;
  right: 0;
  font-size: 2vw;
  font-weight: 600;
  @include breakpoint(medium only) {
    bottom: rem-calc(10);
    width: 100%;
    padding: 0 rem-calc(10);
  }
  @include breakpoint(small only) {
    bottom: rem-calc(10);
    width: 100%;
    padding: 0 rem-calc(10);
    font-size: 3vw;
  }
}

.text-bildblock-title-sub {
  color: $text-bildblock-color;

  .ocker {
    color: $frz-ocker30;
  }
}
.text-bildblock-title {
  color: $frz-ocker30;

  h2 {
    line-height: rem-calc(120);
    color: $frz-ocker30;
    font-size: 4vw;
    font-weight: 900;


    @include breakpoint(small only) {
      line-height: rem-calc(30);
    }
  }

  a {
    color: $white;
    display: block;
    position: relative;
    text-decoration: none;
    overflow: hidden;

    &:hover:after,
     &:focus:after,
     &:active:after {
       width: 100%;
     }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -50%;
      width: 10%;
      transform: translateX(-50%);
      height: 10px;
      background: $white;
      transition: .35s;
    }
  }
}
