$parallax-window-height: 90vh;
$parallax-background-height: $parallax-window-height * 1.5;

.parallax-window {
  max-height: $parallax-window-height;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.parallax-static-content {
  padding: 50% 0;
  position: relative;
  z-index: 9;

  @include breakpoint(large down) {
    padding: 100%;
  }

  h2 {
    color: $white;
    font-weight: 900;
    text-transform: uppercase;
    background-color: rgba($stahlblau, 0.6);

    &:before, &:after {
      width: 100%;
      height: 6px;
      display: block;
      background-color: $frz-ocker30;
      content: "";
      margin: 10px 0;
    }

    // @include breakpoint(small only) {
    //   line-height: rem-calc(30);
    // }
  }
}

.parallax-background {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $stahlblau;
  height: $parallax-background-height;
  left: 0;
  position: absolute;
  top: - $parallax-window-height / 3;
  width: 100%;

  // @include breakpoint(small only) {
  //   height: ($parallax-background-height / 1.05);
  //   top: - $parallax-window-height / 2;
  // }

  @include breakpoint(large down) {
    height: ($parallax-background-height / 1.12);
    top: - $parallax-window-height / 5;
  }
}

// Based on http://codepen.io/skeurentjes/
