$bildblock-color: $leichtblau;
$bildblock-bg: $light-gray;
$bildblock-height: 90vh;
$bildblock-height-medium: 80vh;
$bildblock-height-small: 35vh;

.bildblock {
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  // background-blend-mode: overlay;
  // filter: brightness(80%);
  height: $bildblock-height;
  position: relative;

  @include breakpoint(medium only) {
    height: $bildblock-height-medium;
    background-size: cover;
    background-position: bottom center;
  }
  @include breakpoint(small only) {
    height: $bildblock-height-small;
    background-size: contain;
  }
}

.bildblock-content {
  position: absolute;
  bottom: rem-calc(70);
  left: 1rem;
  right: 1rem;

  @include breakpoint(small only) {
    bottom: rem-calc(20);
  }

  .bildblock-title-sub {
    color: $bildblock-color;
    font-size: 3vw;
  }
  .bildblock-title {
    h2 {
      color: $bildblock-color;
      font-size: 6vw;
      font-weight: 900;
      text-transform: uppercase;
    }

    a {
      display: block;
      position: relative;
      color: $bildblock-color;
      text-decoration: none;
      overflow: hidden;

      &:hover:after,
       &:focus:after,
       &:active:after {
         width: 100%;
       }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: -50%;
        width: 10%;
        transform: translateX(-50%);
        height: 20px;
        background: $frz-ocker30;
        transition: .35s;
        @include breakpoint(small only) {
          height: 4px;
        }
      }
    }
  }
}
