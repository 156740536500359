section {
  margin: 4.16665% 0;
}
.browserupgrade {
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}

a {
  outline: none;
}
